<template>
  <footer class="footer-section email-application">
    <div class="wrap-container">
      <b-row>
        <b-col md="4" class="mb-1 flex">
          <b-row>
            <b-col sm="4" class="mb-1 flex">
              <a
                class="text-white"
                @click="handleContactUs"
              >
                Help & Support
              </a>
            </b-col>
            <b-col sm="4" class="mb-0 flex">
              <router-link
                class="text-white"
                :to="{
                  name: isParticipantPortal
                    ? 'participant-privacy'
                    : 'app-privacy',
                }"
              >
                Privacy Policy
              </router-link>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="4" class="text-center mb-0 copyright">
          <span>&copy; {{ currentYear }} Art of Mentoring</span>
        </b-col>

        <b-col
          sm="4"
          class="text-right d-flex flex-column-reverse flex-sm-column mb-0"
        >
          <p>
            <router-link
              class="text-white"
              :to="{
                name: isParticipantPortal ? 'participant-terms' : 'app-terms',
              }"
            >
              Terms of Acceptable Use
            </router-link>
          </p>
          <p>Platform v2</p>
        </b-col>
      </b-row>
    </div>
    <!-- Email compose modal -->
    <email-compose
      v-model="shallShowEmailComposeModal"
      :isProgramCommunications="true"
      :isPublic="true"
    />
  </footer>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { mapGetters } from "vuex";
import EmailCompose from "@/participants/communications/EmailCompose.vue";
import { ref } from '@vue/composition-api';
import { programTypes } from "@/models";

export default {
  name: "Footer",

  components: {
    BRow,
    BCol,
    EmailCompose
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    ...mapGetters("app", ["isParticipantPortal"]),
    program() {
      return this.$store.getters['participants/program'];
    },
  },
  methods: {
    handleContactUs() {
      if (this.program?.type_id === programTypes.TRAINING) {
        this.shallShowEmailComposeModal = true;
      } else {
        window.open(process.env.VUE_APP_KNOWLEDGEBASE_URL + "/general-support" , "_blank");
      }
    },
  },
  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false);

    return {
      shallShowEmailComposeModal
    };
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
  footer.footer-section .modal-actions{
    color: $body-color;
  }
</style>
